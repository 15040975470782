
import {
  computed,
  defineComponent
} from 'vue'
import { useRoute } from 'vue-router'
import SmsReceivedDetailsButtons from '@/components/pages/history/smsReceived/details/SmsReceivedDetailsButtons.vue'
import SmsReceivedDetails from '@/components/pages/history/smsReceived/details/SmsReceivedDetails.vue'
import type { BreadcrumbsLink } from '@/definitions/shared/types'
import type { SMSReceivedSent } from '@/definitions/history/types'
import { getTableData } from '@/services/tableService'
import { formatDate } from '@/services/dateTimeService'
import PageContent from '@/components/layout/pageContent/PageContent.vue'

const breadcrumbsLink: BreadcrumbsLink[] = [
  {
    label: 'Inbound SMS',
    name: 'base.history.receivedSms',
  },
  {
    label: '6088849',
  },
]

export default defineComponent({
  components: {
    PageContent,
    SmsReceivedDetails,
    SmsReceivedDetailsButtons,
  },
  setup() {
    const route = useRoute()

    const message = computed(() => (getTableData('historySMS') as SMSReceivedSent[])
      .find(el => el.id === route.params.id))

    return {
      breadcrumbsLink,
      message,
      formatDate,
    }
  },
})
