import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_details_table_item = _resolveComponent("details-table-item")!
  const _component_tm_list_chip_recipient = _resolveComponent("tm-list-chip-recipient")!
  const _component_tm_country = _resolveComponent("tm-country")!
  const _component_sms_sent_details_message = _resolveComponent("sms-sent-details-message")!
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_details_table = _resolveComponent("details-table")!

  return (_openBlock(), _createBlock(_component_details_table, null, {
    default: _withCtx(() => [
      _createVNode(_component_details_table_item, {
        label: "Message ID",
        value: "6088849"
      }),
      _createVNode(_component_details_table_item, {
        label: "Receive time",
        value: "8 Jul 10:44 am"
      }),
      _createVNode(_component_details_table_item, {
        label: "From",
        "label-align-center": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_list_chip_recipient, {
            recipient: _ctx.message.to
          }, null, 8, ["recipient"])
        ]),
        _: 1
      }),
      _createVNode(_component_details_table_item, { label: "To" }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_country, { country: {
          id: 'us',
          name: '+37259122132',
          counter: 'US Support',
        } })
        ]),
        _: 1
      }),
      _createVNode(_component_details_table_item, { label: "Message content" }, {
        default: _withCtx(() => [
          _createVNode(_component_sms_sent_details_message, { message: _ctx.message }, null, 8, ["message"])
        ]),
        _: 1
      }),
      _createVNode(_component_details_table_item, {
        label: "Status",
        "label-align-center": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_status, _normalizeProps(_guardReactiveProps(_ctx.message.status)), null, 16)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}