import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sms_received_details_buttons = _resolveComponent("sms-received-details-buttons")!
  const _component_sms_received_details = _resolveComponent("sms-received-details")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_ctx.message)
    ? (_openBlock(), _createBlock(_component_page_content, {
        key: 0,
        "bg-shade": "",
        breadcrumbs: _ctx.breadcrumbsLink,
        title: "Received message details",
        size: "large"
      }, {
        "filter-bar-slot": _withCtx(() => [
          _renderSlot(_ctx.$slots, "filter-bar-slot")
        ]),
        "page-content-top-right": _withCtx(() => [
          _renderSlot(_ctx.$slots, "page-content-top-right")
        ]),
        "page-content-white": _withCtx(() => [
          _createVNode(_component_sms_received_details_buttons, {
            message: _ctx.message,
            class: "mb-6"
          }, null, 8, ["message"]),
          _createVNode(_component_sms_received_details, { message: _ctx.message }, null, 8, ["message"])
        ]),
        _: 3
      }, 8, ["breadcrumbs"]))
    : _createCommentVNode("", true)
}