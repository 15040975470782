
import type { PropType } from 'vue'
import {
  defineComponent
} from 'vue'
import DetailsTable from '@/components/shared/templates/details/DetailsTable.vue'
import DetailsTableItem from '@/components/shared/templates/details/DetailsTableItem.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import type { SMSReceivedSent } from '@/definitions/history/types'
import SmsSentDetailsMessage from '@/components/pages/history/smsSent/details/SmsSentDetailsMessage.vue'
import TmListChipRecipient from '@/components/shared/TmListChipRecipient.vue'
import TmCountry from '@/components/shared/TmCountry.vue'

export default defineComponent({
  components: {
    TmCountry,
    TmListChipRecipient,
    SmsSentDetailsMessage,
    DetailsTable,
    DetailsTableItem,
    TmStatus,
  },
  props: {
    message: {
      type: Object as PropType<SMSReceivedSent>,
      required: true,
    },
  },
})
